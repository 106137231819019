<template>
    <div>
        <loading-component></loading-component>
        <nav-bar-component :navbar="nav_bar" :title_name="my_nav_bar_name"></nav-bar-component>
        <van-cell-group inset >
            <van-cell title="合同单号" :value="details.name" />
            <van-cell title="审批状态">
                <template #right-icon>
                    <van-tag plain color="#ADADAD" v-if="details.state===0">待提交</van-tag>
                    <van-tag plain type="primary" v-if="details.state===1">审批中</van-tag>
                    <van-tag plain type="success" v-if="details.state===2">已通过</van-tag>
                    <van-tag plain type="danger" v-if="details.state===3">已驳回</van-tag>
                </template>
            </van-cell>
            <van-cell title="备案状态">
                <template #right-icon>
                    <van-tag plain color="#ADADAD" v-if="details.record_state===0">未备案</van-tag>
                    <van-tag plain type="success" v-if="details.record_state===1">已备案</van-tag>
                </template>
            </van-cell>
            <van-cell title="合同标题" :value="details.title" />
            <van-cell title="我方企业" :value="details.enterprise_name" />
            <van-cell title="主体数量" :value="['双方协议','三方协议','四方协议'][details.subject_num]" />
            <van-cell title="合同类型" :value="['采购','销售'][details.contract_type]" />
            <van-cell title="业务类型" :value="AllBusinessType[details.business_type]" />
            <van-cell title="甲方公司" :value="details.company_a_name" />
            <van-cell title="甲方联系人" :value="details.company_a_person" />
            <van-cell title="甲方电话" :value="details.company_a_phone" />
            <van-cell title="乙方公司" :value="details.company_b_name" />
            <van-cell title="乙方联系人" :value="details.company_b_person" />
            <van-cell title="乙方电话" :value="details.company_b_phone" />
            <div v-if="details.subject_num>0">
                <van-cell title="丙方公司" :value="details.company_c_name" />
                <van-cell title="丙方联系人" :value="details.company_c_person" />
                <van-cell title="丙方电话" :value="details.company_c_phone" />
            </div>
            <div v-if="details.subject_num===2">
                <van-cell title="丁方公司" :value="details.company_d_name" />
                <van-cell title="丁方联系人" :value="details.company_d_person" />
                <van-cell title="丁方电话" :value="details.company_d_phone" />
            </div>
            <van-cell title="开始日期" :value="details.start_date" />
            <van-cell title="结束日期" :value="details.end_date" />
            <van-cell title="发票类型" :value="['普通增值税发票','专用增值税发票','商业发票','财务收据'][details.invoice_type]" />
            <van-cell title="结算币种" :value="details.settlement_currency | settlement_currency_name" />
            <van-cell title="结算方式" :value="settlement_mode_name" />
            <van-cell title="结算周期" :value="AllSettlementCycle[details.settlement_cycle]" />
            <van-cell title="支付方式" :value="details.pay_way | pay_way_name" />
            <van-cell title="赔付风险" :value="details.risk" v-if="details.risk" />
            <van-cell title="合同备注" :value="details.contract_note" v-if="details.contract_note" />
            <van-cell title="负责人" :value="details.staff_name" />
            <van-cell title="附件">
                <template #label>
                    <uploader-component
                        :farr="details.contract_other"
                        :show_upload="false"
                        :deletable="false"
                    ></uploader-component>
                </template>
            </van-cell>
        </van-cell-group>
        <div class="sub_button">
            <van-button v-if="to_approval_auth" round block type="primary" @click="to_approval()">提交审批</van-button>
        </div>
        <div class="sub_button">
            <van-button v-if="sel_approval_auth" round block type="warning" @click="sel_approval()">查看审批</van-button>
        </div>
        <div class="sub_button">
            <van-button v-if="withdraw_auth" round block type="danger" @click="ContractWithdraw()">审批撤回</van-button>
        </div>
    </div>
</template>

<script>
import LoadingComponent from '@/components/common/LoadingComponent.vue'
import NavBarComponent from '@/components/common/NavBarComponent.vue'
import SelectComponent from '@/components/common/SelectComponent.vue'
import UploaderComponent from '@/components/common/UploaderComponent.vue'

import { 
    contract_details_request,
    contract_to_approval_request,
    contract_withdraw_request
    } from '@/network/finance/Contract.js'
import { enterprise_list_request,staff_list_request } from '@/network/list.js'

export default {
    name:'ContractDetailsComponent',
    data(){
        return {
            nav_bar: {
                left: true,
                right: {
                    type: '',
                    name: ''
                },
            },
            id: 0,
            details: {
                settlement_currency:[],
                pay_way: [],
                contract_other: []
            },
            all_enterprise: [],
            all_staff: [],
            AllBusinessType: ['服务','装卸','仓库租赁','仓储保管','公路运输','公路运输','报关报检','多式联运','码头装卸(理货)'],
            AllSettlementMode: ['收到发票后','货物签收后','报关后','货物到港后','驳船开后','海船开后'],
            AllSettlementDayType: ['自然日','工作日'],
            AllSettlementCycle: ['付款买单','票结','月结','预付款'],
            //提交
            to_approval_auth: false,
            //查看审批
            sel_approval_auth: false,
            //审批
            ApprovalAuth: false,
            //撤回审批
            withdraw_auth: false
        }
    },
    computed:{
        my_nav_bar_name() {
            return '合同详情'
        },
        settlement_mode_name() {
            if((this.details.settlement_mode !== '') && (this.details.settlement_day_type !== '')) {
                return this.AllSettlementMode[this.details.settlement_mode] + this.details.settlement_day_num+'个'+this.AllSettlementDayType[this.details.settlement_day_type]+'内'
            } else {
                return ''
            }
        }
    },
    methods:{
        get_contract_details() {
            this.$store.commit('true_loading')
            contract_details_request(this.id)
                .then((s) => {
                    if (s.status === 0) {
                        this.details = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => {
                    this.$store.commit('false_loading')
                    this.is_auth()
                })
        },
        back_details() {
            this.get_contract_details()
        },
        sel_approval() {
            this.$router.push({
                name: 'ApprovalComponent',
                query: {
                    type: 14,
                    id: this.id,
                    ApprovalAuth: this.ApprovalAuth
                }
            })
        },
        to_approval() {
            this.$dialog.confirm({
                title: '温馨提示',
                message: '是否确定提交审批？',
            })
            .then(() => {
                this.$store.commit('true_loading')
                contract_to_approval_request({id:this.id,note:''})
                        .then((s) => {
                            if (s.status === 0) {
                                this.$toast.success('已提交!')
                                this.get_contract_details()
                            } else {
                                this.$toast.fail(s.msg)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                        .finally(() => this.$store.commit('false_loading'))
            })
            .catch(() => {});
        },
        ContractWithdraw() {
            this.$dialog.confirm({
                title: '温馨提示',
                message: '是否确定撤回？（撤回后再发起需要重新走流程）',
            })
            .then(() => {
                this.$store.commit('true_loading')
                contract_withdraw_request({id:this.id})
                        .then((s) => {
                            if (s.status === 0) {
                                this.$toast.success('已撤回!')
                                this.get_contract_details()
                            } else {
                                this.$toast.fail(s.msg)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                        .finally(() => this.$store.commit('false_loading'))
            })
            .catch(() => {});
        },
        get_all_enterprise() {
            this.$store.commit('true_loading')
            enterprise_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.all_enterprise = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        get_all_staff() {
            this.$store.commit('true_loading')
            staff_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.all_staff = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        confirm_enterprise(e) {
            this.details.enterprise_id = e.id
        },
        confirm_staff(e) {
            this.details.staff_id = e.id
        },
        update_img(arr) {
            this.details.other = arr
        },
        is_auth() {
            this.to_approval_auth = false
            this.sel_approval_auth = false
            this.ApprovalAuth = false
            this.withdraw_auth = false

            if(this.details.state === 0) {
                //判断创建人或申请人是否为自己
                if((this.details.staff_id === this.$store.state.user.id) || (this.details.create_staff === this.$store.state.user.id)) {
                    this.to_approval_auth = this.$_has('提交合同')
                }
            } else if(this.details.state === 1) {
                 //判断创建人或申请人是否为自己
                if((this.details.staff_id === this.$store.state.user.id) || (this.details.create_staff === this.$store.state.user.id)) {
                    this.withdraw_auth = this.$_has('撤回合同')
                }
                //判断当前审批人是否为自己
                if(this.details.current_examine_staff_id === this.$store.state.user.id) {
                    this.ApprovalAuth = this.$_has('审批合同')
                }
                this.sel_approval_auth = this.$_has('查看合同审批记录')
            } else if(this.details.state === 2) {
                this.sel_approval_auth = this.$_has('查看合同审批记录')
            } else if(this.details.state === 3) {
                //判断创建人或申请人是否为自己
                if((this.details.staff_id === this.$store.state.user.id) || (this.details.create_staff === this.$store.state.user.id)) {
                    this.to_approval_auth = this.$_has('提交合同')
                }
                this.sel_approval_auth = this.$_has('查看合同审批记录')
            }
        },
    },
    filters:{
        settlement_currency_name(v) {
            let currency_name = ''
            v.forEach(e => {
                if(currency_name) {
                    currency_name += (' + '+['人名币','美元','欧元','日元'][e])
                } else {
                    currency_name = ['人名币','美元','欧元','日元'][e]
                }
            })

            return currency_name
        },
        pay_way_name(v) {
            let pay_name = ''
            v.forEach(e => {
                if(pay_name) {
                    pay_name += (' + '+['现金支付','银行承兑','商业承兑','转账汇款'][e])
                } else {
                    pay_name = ['现金支付','银行承兑','商业承兑','转账汇款'][e]
                }
            })

            return pay_name
        }
    },
    props:{},
    created(){
        this.id = this.$route.query.id
        this.get_contract_details()
        this.get_all_enterprise()
        this.get_all_staff()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        LoadingComponent,
        NavBarComponent,
        SelectComponent,
        UploaderComponent
    },
    watch:{}
}
</script>

<style>
.sub_button {
    margin: 0.5rem;
}
.van-cell__value {
    white-space: pre-line
}
</style>